import css from 'styled-jsx/css'

export const spreadCss =  css`
  .spread {
    position: relative;
    user-select: none;
    box-sizing: border-box;
  }

  .spread .border-top {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    z-index: 10;
    height: 1px;
    width: 100%;
    margin-bottom: -1px;
    box-sizing: border-box;
  }

  .spread .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);
    position: relative;
    z-index: 10;
    height: 1px;
    width: 100%;
    margin-top: -1px;
    margin-bottom: -1px;
    box-sizing: border-box;
  }

  .spread.edit-mode {
    user-select: none;
  }

  .spread.edit-mode :global([asset][editable]) {
    cursor: grab;
  }

  .spread.edit-mode :global([asset][editable]):active {
    cursor: grabbing;
  }

  .spread.edit-mode :global(text) {
    pointer-events: none;
  }

  .spread.edit-mode:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    border: 1px solid rgba(var(--border1), 1);
    z-index: 10;
    pointer-events: none;
  }

  .spread.edit-mode[page-number]:not([page-number=""]):after {
    content: attr(page-number);
    color: white;
    background-color: rgba(255, 255, 255, 0.2);
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-family: verdana;
    pointer-events: none;
  }

  .spread.edit-mode.selected[page-number]:not([page-number=""]):after {
    background-color: rgba(var(--albumSelection), 1);
    color: black;
  }

  .spread.edit-mode.selected:before {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    z-index: 10;
    pointer-events: none;
    border: 3px solid rgba(var(--albumSelection), 1);
  }

  .spread :global(a) {
    cursor: pointer;
  }

  .spread:not(.edit-mode):not([view-mode="cover"]) :global([asset]:not([is-processing])) {
    cursor: zoom-in;
  }

  .spread:not(.edit-mode) :global([asset=svg]) {
    cursor: default !important;
    pointer-events: none;
  }

  .spread:not(.edit-mode) :global([asset=text]) {
    user-select: text;
    cursor: default !important;
  }

  .spread:not(.edit-mode) :global(text, tspan) {
    user-select: text;
  }

  .cover-arrow {
    background-color: white;
    width: 50px;
    height: 35px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom: 0px;
    position: absolute;
    z-index: 10;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    transition: all 0.2s cubic-bezier(0.28, 0.84, 0.42, 1);
  }

  .cover-arrow:hover {
    height: 45px;
    width: 60px;
  }

  .cover-arrow .icon * {
    stroke: #000;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: none;
  }

  .cover-arrow .icon #path_1 {
    stroke-width: 1.25px;
    stroke-dashoffset: 0;
    stroke-dasharray: 213;
    opacity: 0;
  }

  .cover-arrow .icon #path_2 {
    stroke-width: 1.25px;
    stroke-dasharray: 17;
    stroke-dashoffset: 0;
  }
`

export const pagerowCss =  css`
  .page-row {
    position: relative;
    z-index: 1;
    overflow: hidden;
    box-sizing: border-box;
  }

  .page-row svg {
    overflow: hidden;
    position: absolute;
  }
`

export const spreadMessageCss = css`
  .spread-message-wrapper {
    flex: 1;
    flex-direction: row;
    align-items: center;
    align-content: center;
    display: flex;
  }

  .spread-message-wrapper .spread-message {
    background-color: white;
    padding: 10px 20px;
    border-left: 5px solid transparent;
    margin: 20px;
  }

  .spread-message-wrapper .spread-message>.spread-message-type {
    text-transform: uppercase;
    font-weight: bold;
  }

  .spread-message-wrapper .spread-message.spread-message.warning {
    border-color: yellow;
  }

  .spread-message-wrapper .spread-message.spread-message-error {
    border-color: red;
  }
`

export const attachmentCss = css`
  .page-attachment {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    margin-bottom: -1px; /* ivm border die we toevoegen bij de arrows */
  }

  .page-attachment.arrow-bottom:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid white;
    border-top-color: inherit;
    position: absolute;
    bottom: -5.5px;
    left: 50%;
    margin-left: -6px;
  }

  .page-attachment.arrow-bottom:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid rgba(0, 0, 0, 0);
    position: absolute;
    bottom: -6px;
    left: 50%;
    margin-left: -6px;
  }

  .page-attachment.arrow-top:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid white;
    border-bottom-color: inherit !important;
    position: absolute;
    top: -5.5px;
    left: 50%;
    margin-left: -6px;
  }

  .page-attachment.arrow-top:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid rgba(0, 0, 0, 0);
    position: absolute;
    top: -6px;
    left: 50%;
    margin-left: -6px;
  }

  .page-attachment.prepend.first:not(.last) :global(> *),
  .page-attachment.append.first:not(.last) :global(> *) {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .page-attachment.prepend.last:not(.first) :global(> *),
  .page-attachment.append.last:not(.first) :global(> *) {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .page-attachment.prepend:not(.first):not(.last) :global(> *),
  .page-attachment.append:not(.first):not(.last) :global(> *) {
    border-radius: 0px !important;
  }
`;

export const printCss = css`
  :global(html) {
    -webkit-print-color-adjust: exact;
  }
  :global(body, html) {
    padding: 0;
    margin: 0;
    background-color: white;
  }
  :global(.pages > div) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    page-break-before: always;
    break-before: all;
    break-after: all;
    break-inside: avoid;
  }
  :global(.pages > div > svg) {
    width: 100%;
    height: 100%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  :global(svg:not(root)) {
    overflow: visible;
  }
  
`;
