import {getProp} from 'utils/Utils';
import { toJS } from 'mobx';
import {getBoundingBoxFromFrame} from 'utils/Utils';

class LayerModel {
  data = {};
  layers = [];

  constructor(data, parent)
  {
    this.parent = parent;
    this.data = data;

    if(parent)
      parent.layers.push(this);
  }

  get x() {
    const rotation = getProp(this.data, ['style', 'rotation']);
    let box = getBoundingBoxFromFrame(this.data.frame, rotation);
    let x = box.x1;

    if(this.parent)
      x += this.parent.x;

    return x;
  }

  get y() {
    const rotation = getProp(this.data, ['style', 'rotation']);
    let box = getBoundingBoxFromFrame(this.data.frame, rotation);

    let y = box.y1;
    if(this.parent)
      y += this.parent.y;

    return y;
  }

  get width() {
    return this.data.frame.width;
  }

  get height() {
    return this.data.frame.height;
  }

  get asset() {
    return getProp(this.data, ['style', 'content', 'asset']);
  }

  get outsideBorder() {
    const borderSize = getProp(this.data, ['style', 'border', 'thickness']);
    const borderPosition = getProp(this.data, ['style', 'border', 'position']);

    if(borderPosition==='center')
      return borderSize/2;

    return 0;
  }

  walk(callback) {
    for(let layer of this.layers) {
      callback(layer);
      layer.walk(callback);
    }
  }
}

function tree(layers, parent) {
  for(let layer of layers) {
    let layerData = {...layer};
    delete layerData.layers;

    let l = new LayerModel(layerData, parent);
    if(layer.layers!==undefined && layer.layers.length>0) {
      tree(layer.layers, l);
    }
  }
}

LayerModel.createTree = function(data, layers) {
  data = {...toJS(data)};
  delete data.layers;

  let layer = new LayerModel(data);
  tree(layers, layer);
  return layer;
}
export default LayerModel;