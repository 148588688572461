import { createRef, PureComponent } from 'react';
import {toJS} from 'mobx';
import {FetchJSON} from 'utils/Fetch';
import isDev from "utils/isDev";
import style from './style';
const isServerSide = typeof window === 'undefined';

if(!isServerSide) {
  // Preload Blueprint
  import(
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    /* webpackPreload: true */
    '@myalbum/blueprint'
  ).then( () => {});
}

const _highlightLayer = {target: undefined};
function highlightLayer(blueprint, targetLayer) {
  if(!blueprint)
    return;

  if(targetLayer===false || targetLayer===undefined)
  {
    if(blueprint._highlightedLayer)
      blueprint._highlightedLayer.highlight(false);
  }else if(targetLayer) {
    if(_highlightLayer.target && _highlightLayer.target!==targetLayer)
    {
      // Andere highlight layer, dus oude niet meer highlighten
      _highlightLayer.target.highlight(false);
    }

    _highlightLayer.target = targetLayer;
    targetLayer.highlight('rgba(0,0,0, 0.5)');
  }

  blueprint._highlightedLayer = targetLayer;
}

function getLayerByOffset(offset, blueprint) {
  if(!blueprint || !offset || offset.x===undefined || offset.y===undefined)
    return false;

  let el = document.elementFromPoint(offset.x, offset.y);
  if(el)
  {
    const pointerLayer = blueprint.getPointerElLayer(el);
    return pointerLayer;
  }
}

function getLayerByMetaName(name, blueprint) {
  if(!blueprint)
    return false;

  return blueprint.getLayerByMetaName(name);
}

class BlueprintComponent extends PureComponent {
  static defaultProps = {
    eventListener: () => {},
    onMount: () => {},

    config: {},
  }

  constructor(props) {
    super(props);

    this.ref = createRef();
    this._mounted = false;
    this.updateCache = {};
    this.disposers = [];
    this.assetDisposers = {};
    this.assetChangeCallbacks = {};
  }

  getLayerByOffset(offset) {
    return getLayerByOffset(offset, this.blueprint);
  }

  getLayerByMetaName(name) {
    return getLayerByMetaName(name, this.blueprint);
  }

  highlightLayer(layer) {
    highlightLayer(this.blueprint, layer);
  }

  highlightArea(color, frame) {
    if(this.blueprint)
      this.blueprint.highlightArea(color, frame);
  }

  eventListener = (e) => {
    const data = {
      eventType: e.eventType,
      event: e.event,
      layer: e.layer!==undefined ? e.layer.meta.name : undefined,
      _UNSAFE_layer: e.layer,
    }

    this.props.eventListener(data);
  }

  componentDidMount() {
    if(isServerSide)
      return;

    this._mounted = true;
    this.dataUpdate({});
    this.renderUpdate();
    if(this.props.onMount) {
      this.unmountCallback = this.props.onMount(this.ref.current);
    }
  }

  componentDidUpdate(prevProps) {
    this.dataUpdate(prevProps);
    this.renderUpdate();
  }

  loadData(url) {
    if(this._url===url)
      return this._urlPromise;

    if(this._cancelPromise)
      this._cancelPromise();

    this._url = url;

    const [promise, cancel] = FetchJSON(url, {
      cache: true,
      fetch: {
        mode: 'cors',
      }
    })
    promise.then(({data}) => {
      this.data = data;
      this.renderUpdate();
    });
    promise.catch(e => {
      console.error(e);
    })

    this._urlPromise = promise;
    this._cancelPromise = cancel;
  }

  dataUpdate(prevProps) {
    if(this.props.url!==undefined && prevProps.url!==this.props.url)
    {
      this.url = this.props.url;
      this.data = undefined;
      this.loadData(this.url);
    }else if(this.props.data!==prevProps.data)
    {
      this.url = undefined;
      this.data = this.props.data;
    }
  }

  renderUpdate() {
    // We hebben al deze data nodig, anders mag blueprint nog niet geladen worden
    const loaded = this.data!==undefined && this.props.extensions!==undefined && this.props.layerData!==undefined;
    if(!loaded)
      return;

    this.setBlueprintDataAndExtensions(this.data, this.props.extensions)
      .then(() => {
        // Onzin regel, maar dit voorkomt een bug in de compiler?
        // Enable/disable paar keer editmode en kijk naar subtitel in cover
        // eslint-disable-next-line
        this._test = this.blueprint._uid;

        // Onderstaande functies zijn slim en updaten alleen indien nodig
        this.setRightFrame();
        this.setLeftFrame();
        this.setLayerData();
        this.setViewBox();
      })
      .catch(() => {
        // Blueprint is nog niet klaar
      });
  }

  componentWillUnmount() {
    this._mounted = false;
    for(let disposer of this.disposers)
      disposer();

    for(let disposerId in this.assetDisposers) {
      this.assetDisposers[disposerId]();
    }

    if(this.blueprint)
    {
      this.blueprint.destroy();
      this.blueprint = null;
    }

    if(this.dragTarget)
      this.dragTarget.destroy();

    if(this.unmountCallback)
      this.unmountCallback();
  }

  // Knipt deel van blueprint uit
  setViewBox() {
    if(!this.blueprint)
      return;

    const viewBox = this.props.viewBox!==undefined ? {...toJS(this.props.viewBox)} : "full";
    const str = JSON.stringify(viewBox);
    if(this.updateCache.setViewBox===str)
      return;

    this.updateCache.setViewBox = str;
    this.blueprint.setViewBox(viewBox);
    this.blueprint.render();
  }

  // Verplaatst rechterpagina indien nodig
  setRightFrame() {
    const frame = toJS(this.props.rightFrame);
    if(frame===undefined || !this.right)
      return;

    const str = JSON.stringify(frame);
    if(this.updateCache.setRightFrame===str)
      return;

    this.right.setData({
      frame: frame
    }, true);

    this.updateCache.setRightFrame = str;
  }

  // Verplaatst linkerpagina indien nodig
  setLeftFrame() {
    const frame = toJS(this.props.leftFrame);
    if(frame===undefined || !this.left)
      return;

    const str = JSON.stringify(frame);
    if(this.updateCache.setLeftFrame===str)
      return;

    this.left.setData({
      frame: frame
    }, true);

    this.updateCache.setLeftFrame = str;
  }

  // Voegt blueprintextensions toe en daarna layerdata
  setLayerData() {
    if(!this.blueprint)
      return;

    const str2 = JSON.stringify(this.props.layerData);
    let layerDataChanged = (this.updateCache.layerData!==str2);
    this.updateCache.layerData = str2;

    if(layerDataChanged)
    {
      this.blueprint.setLayerData(this.props.layerData);
    }
  }

  // Basis blueprintdata instellen
  setBlueprintDataAndExtensions(data, extensions) {
    if(isServerSide)
      return Promise.resolve();

    if(!this.ref.current)
    {
      return Promise.reject();
    }

    const strData = JSON.stringify(data);
    const strExtensions = JSON.stringify(extensions);
    if(strData===this._blueprintData && strExtensions===this._extensions && this.setPromise)
    {
      // Blueprintdata en extensions niet veranderd, resolve oude promise
      return this.setPromise;
    }

    this._blueprintData = strData;
    this._extensions = strExtensions;
    this.updateCache = {};
    this.renderedOnce = false;
    this.right = undefined;

    this.setPromise = new Promise((resolve, reject) => {
      // reset blueprint
      if(this.blueprint)
        this.blueprint.destroy();

      delete this.blueprint;

      import('@myalbum/blueprint').then(result => {
        if(!this.ref.current)
          return reject('Not mounted');

        if(strData!==this._blueprintData)
          return reject('Blueprint data changed');

        const config = {...{
          videoControls: true,
        }, ...this.props.config};

        // Fallback fonts
        if(config.fallbackFont===undefined)
          config.fallbackFonts = [];

        // Emojis altijd afvangen
        //config.fallbackFonts.push('emoji');
        config.fallbackFonts.push('Noto Emoji');
        
        // Overigen onbekende karakters afvangen?
        if(config.useFontFallback)
          config.fallbackFonts.push('TextSecuritySquare');

        config.fallbackFont = config.fallbackFonts.join(', ');
        

        const Blueprint = result.Blueprint;
        this.blueprint = new Blueprint(data, {
          holder: this.ref.current,
          ...config,
          debug: isDev,

          width: this.props.style.width,
          height: this.props.style.height,
        });
        this.blueprint.on('click', this.eventListener);
        this.blueprint.on('mousedown', this.eventListener);
        this.blueprint.on('mouseup', this.eventListener);
        this.blueprint.on('dblclick', this.eventListener);
        this.blueprint.on('contextmenu', this.eventListener);
        this.blueprint.on('longtouch', this.eventListener);
        this.blueprint.on('load', this.eventListener);
        this.blueprint.setExtensions(extensions);

        // Haal layer right op (voor covers)
        this.right = this.blueprint.getLayerByName('right');
        this.left = this.blueprint.getLayerByName('left');
        resolve();
      });
    })
    .catch(() => {
      // Mislukt om diverse redenen, oude promise verwijderen, volgende keer nieuwe kans
      this.setPromise = undefined;
    });

    return this.setPromise;
  }

  render() {
    return (
      <>
        <style jsx>{style}</style>
        <div
          style={this.props.style}
          ref={this.ref}
        >
        </div>
      </>
    );
  }
}

export default BlueprintComponent;