import { observable, computed, toJS, runInAction, action, reaction, keys, remove, transaction, makeObservable } from "mobx";
import { extendObservable } from 'mobx';
import extend from 'utils/extend';
import {addProp, getProp} from 'utils/Utils';
import {FetchJSON} from 'utils/Fetch';
import Layer from './layer-model';

function getLayersByKey(layers, key, value) {
  let found = [];
  for(let layer of layers) {
    if(layer[key]!==undefined && layer[key]===value)
    {
      if(key==='id')
        return layer;

      found.push(layer);
    }

    if(layer.layers && layer.layers.length>0) {
      let result = getLayersByKey(layer.layers, key, value);
      if(key==='id')
        return result;

      found = found.concat(result);
    }
  }

  return found;
}

function getLayersByMetaKey(layers, key, value) {
  let found = [];
  for(let layer of layers) {
    if(layer.meta && layer.meta[key]!==undefined && layer.meta[key]===value)
      found.push(layer);

    if(layer.layers && layer.layers.length>0) {
      let result = getLayersByMetaKey(layer.layers, key, value);
      found = found.concat(result);
    }
  }

  return found;
}

function walkLayers(layers, callback) {
  for(let layer of layers) {
    callback(layer);

    if(layer.layers && layer.layers.length>0) {
      walkLayers(layer.layers, callback);
    }
  }
}

function filterLayersByMetaKey(layers, key, value, callback) {
  for(let index=0; index<layers.length; index++) {
    let layer = layers[index];
    if(layer.meta && layer.meta[key]!==undefined && layer.meta[key]===value)
    {
      callback(layer, layers, index);
    }

    if(layer.layers && layer.layers.length>0) {
      filterLayersByMetaKey(layer.layers, key, value, callback);
    }
  }
}

function setLayerData(layers, data) {
  for(const target in data) {
    const targetData = data[target];
    filterLayersByMetaKey(layers, 'name', target, (layer) => {
      runInAction(() => {
        const extendedLayer = extend(true, layer, targetData);
        for(let key in extendedLayer)
        {
          layer[key] = extendedLayer[key];
        }
      });
    });
  }
}

function deleteNull(obj) {
  runInAction(() => {
    for(let prop in obj) {
      if(obj[prop]===null)
        delete obj[prop];
      else if(Object.prototype.toString.call(obj[prop]) === "[object Object]")
      {
        deleteNull(obj[prop]);
      }
    }
  });
}

class PageModel {
  data = {};
  blueprint = {};
  _autoPushToServer = 1;
  wantsServerPush = 0;
  zoomTextLayers = [];
  disposers = [];

  constructor(data)
  {
    makeObservable(this, {
      data: observable,
      blueprint: observable,
      setLayerProperty: action,
      wantsServerPush: observable,
      addExtensions: action,
      removeExtension: action,
      setExtensionProperty: action,
      setData: action,

      artboard: computed({keepAlive: true}),
      layers: computed({keepAlive: true}),
      extensions: computed({keepAlive: true}),
      layerData: computed({keepAlive: true}),
      tempLayerData: computed({keepAlive: true}),
    });

    this.design = data.design;
    this.index = data.index;
    this.setData(data.data);

    this.watchForChanges();
  }

  setData(data) {
    if(typeof data === 'function')
    {
      this._data = data;
      return;
    }

    if(data!==undefined)
    {
      transaction(() =>
      {
        const mykeys = keys(this.data);
        for(let key of mykeys)
        {
          remove(this.data, key);
        }

        extendObservable(this.data, data);
      });
    }
  }

  get autoPushToServer() {
    return this._autoPushToServer>0;
  }

  set autoPushToServer(state) {
    if(state)
      this._autoPushToServer++;
    else
      this._autoPushToServer--;

    if(this._autoPushToServer>1)
      this._autoPushToServer = 1;
  }

  watchForChanges() {
    this.disposers.push(
      reaction(
        () => [toJS(this.data.layerData), toJS(this.data.blueprintExtensions)],
        () => {
          if(this.autoPushToServer)
          {
            this.wantsServerPush++;
          }
        }
      )
    );
  }

  setZoomTextLayers(layers) {
    this.zoomTextLayers = layers;
  }

  setConfig(config) {
    this.width = config.width;
    this.height = config.height;
    this.crop = config.crop;
    this.type = config.type!==undefined ? config.type : 'blueprint';
    this.moveRight = config.moveRight;
    this.padding = config.padding!==undefined ? config.padding: 0;
    this.background = config.background!==undefined ? config.background : undefined;
  }

  // Blueprint instellen voor deze page
  setBlueprint(blueprint) {
    if(blueprint.url===this.blueprint.url)
      return Promise.resolve();

    new Promise(resolve => {
      if(blueprint.data===undefined && blueprint.url) {
        const [promise] = FetchJSON(blueprint.url)
        promise.then(({data}) => {
          resolve(data);
        });
      }else
      {
        resolve(blueprint.data);
      }
    })
    .then(data => {
      runInAction(() => {
        blueprint.data = data;
        this.blueprint = blueprint;
      });
    });
  }

  ready() {
    const promises = [
      this.getData(),
      new Promise(resolve => {
        if(this.blueprint.data===undefined)
        {
          // Er is nog geen blueprint data
          // forceer touchLayout en wacht op setBlueprint event
          // Daarna hebben we alle data die we nodig hebben om een slideviewer te kunnen tonen
          reaction(
            () => this.blueprint.data,
            (value, prevValue, reaction) => {
              reaction.dispose();

              this.getData().then(() => {
                resolve();
              });

            }
          );
        }else
          resolve();
      })
    ];

    return Promise.all(promises);
  }


  /*
    {
      layer: 'photo1',
      path: ['style', 'content'],
      value: {type: "smartfill", asset: "VZeuq6PmSqguJe"},
      temp: true
    }

    layer = layer meta name,
    path = geeft aan welke property de target is,
    value = geeft aan welke properties ingesteld moeten worden op path,
    temp = tijdelijke waarde, deze zal nooit naar de server gestuurd worden
  */
  setLayerProperty(config) {
    const layerExists = config.layer==='Artboard' || this.getLayerByMetaName(config.layer).get()!==undefined;
    if(!layerExists)
      return;

    transaction(() => {
      if(config.temp && this.data.tempLayerData===undefined)
      {
        extendObservable(this.data, {
          tempLayerData: {}
        });
      }

      if(!config.temp) {
        // We hebben een nieuwe niet-temp waarde, delete de temp waarde maar
        this.setLayerProperty({...config, value: null, temp: true});
      }

      addProp(config.temp ? this.data.tempLayerData : this.data.layerData, [config.layer, ...config.path], config.value);
      deleteNull(config.temp ? this.data.tempLayerData : this.data.layerData);
    });
  }

  get artboard() {
    if(!this.blueprint.data)
      return undefined;

    const artboard = {...toJS(this.blueprint.data.artboard)};
    addProp(artboard, ['meta', 'name'], 'Artboard');
    setLayerData([artboard], toJS(this.layerData));

    return artboard;
  }

  get artboardFill() {
    if(!this.blueprint.data)
      return undefined;

    const fill = getProp(this.artboard, ['style', 'fill', 'color', 'rgba']);
    return  fill ? fill : [255,255,255,1];
  }

  get layers() {
    if(!this.blueprint.data)
      return [];

    const layers = toJS(this.blueprint.data.artboard.layers);
    if(this.extensions.Artboard!==undefined)
    {
      runInAction(() => {
        for(const myLayer of toJS(this.extensions).Artboard.layers) {
          layers.push(myLayer);
        }
      });
    }

    setLayerData(layers, toJS(this.extensions));
    setLayerData(layers, toJS(this.layerData));

    return layers;
  }

  walkLayers(callback) {
    return walkLayers(this.layers, callback);
  }

  // Haalt actuele data op voor layer uit extensions en layerData
  getLayerByName(name) {
    return computed(() => {
      let foundLayer = getLayersByKey(this.layers, 'name', name);
      if(foundLayer.length>0)
        return foundLayer[0];
    }, {
      name: 'getLayerByName',
      keepAlive: true,
    });
  }

  // Haalt actuele data op voor layer uit extensions en layerData
  getLayerByMetaName(name) {
    return computed(() => {
      let foundLayer = getLayersByMetaKey(this.layers, 'name', name);
      if(foundLayer.length>0)
        return foundLayer[0];
    }, {
      name: 'getLayerByMetaName',
      keepAlive: true,
    });
  }

  // Haalt actuele data op voor layer uit extensions en layerData
  getLayersByMetaKey(key, value) {
    return computed(() => {
      let foundLayer = getLayersByMetaKey(this.layers, key, value);
      return foundLayer;
    }, {
      name: 'getLayersByMetaKey',
      keepAlive: true,
    });
  }

  // Haalt actuele data op voor layer uit extensions en layerData
  getLayerById(id) {
    return computed(() => {
      return getLayersByKey(this.layers, 'id', id);
    }, {
      name: 'getLayerById',
      keepAlive: true,
    });
  }

  // Haalt data op van deze page, indien nodig komt er pageData via de spread
  getData() {
    return new Promise(resolve => {
      if(typeof this._data === "function")
      {
        this._data().then(data => {
          delete this._data;
          this.autoPushToServer = false;
          this.setData(data);
          this.autoPushToServer = true;

          resolve(this.data)
        });
      }else
      {
        resolve(this.data);
      }
    });
  }

  get extensions() {
    return this.data.blueprintExtensions ? this.data.blueprintExtensions[this.blueprint.key]!==undefined ? this.data.blueprintExtensions[this.blueprint.key] : {} : {};
  }

  get layerData() {
    return this.data.layerData;
  }

  get tempLayerData() {
    return this.data.tempLayerData ? this.data.tempLayerData : {};
  }

  addExtensions(target, layers) {
    // blueprintExtensions in data aanmaken als deze ontbreken
    if(this.data.blueprintExtensions===undefined)
      extendObservable(this.data, {blueprintExtensions: {}});

    // Huidige extensions ophalen, anders empty array
    const extensions = this.extensions[target] ? this.extensions[target].layers ? this.extensions[target].layers : [] : [];
    const newExtensions = extensions.concat(layers); // oude extensions + de nieuwe
    addProp(this.data.blueprintExtensions, [this.blueprint.key, target, 'layers'], newExtensions);
  }

  removeExtension(name) {
    if(this.data.blueprintExtensions===undefined)
      return;

    const extensions = (this.data.blueprintExtensions[this.blueprint.key]);
    for(let target in extensions) {
      filterLayersByMetaKey(extensions[target].layers, 'name', name, (layer, parent) => {
        runInAction(() => {
          parent.remove(layer);
        });
      });
    }

    // Layerdata en tempLayerdata opschonen
    runInAction(() => {
      remove(this.data.layerData, name);
      if(this.data.tempLayerData)
        remove(this.data.tempLayerData, name);
    });
  }

  setExtensionProperty(config) {
    if(this.data.blueprintExtensions===undefined)
      return;

    const extensions = (this.data.blueprintExtensions[this.blueprint.key]);
    for(let target in extensions) {
      filterLayersByMetaKey(extensions[target].layers, 'name', config.layer, (layer) => {
        runInAction(() => {
          addProp(layer, [...config.path], config.value);
          deleteNull(layer);
        });
      });
    }
  }

  removeLayer(name, autoPushToServer=true) {
    transaction(() => {
      // Deze changes hoeven niet opgeslagen te worden,
      // We willen hierna de spread regeneraten
      if(!autoPushToServer)
        this.autoPushToServer = false;

      this.removeExtension(name);

      this.setLayerProperty({
        layer: name,
        path: ['style', 'display'],
        value: 'none'
      });

      // Layer fill transparent maken waardoor een re-render getriggerd wordt
      this.setLayerProperty({
        layer: name,
        path: ['style', 'content'],
        value: {type: 'flat-color', color: {rgba: [0,0,0,0]}}
      });

      if(!autoPushToServer)
        this.autoPushToServer = true;
    });
  }

  cutout(allowedAssetTypes) {
    if(!allowedAssetTypes || allowedAssetTypes.length===0)
      return;

    let frame = {
      left: undefined,
      right: undefined,
      top: undefined,
      bottom: undefined,
      width: undefined,
      height: undefined,
    };

    // Maak een tree van alle layers, hierdoor krijgen we gratis de juiste x en y waarden b.v.
    let tree = Layer.createTree(this.artboard, this.layers);

    // We doorzoeken de tree op zoek naar allowedAssetTypes, deze knippen we uit
    tree.walk(layer => {
      const asset = layer.asset;
      if(asset && allowedAssetTypes.includes(asset.type))
      {
        let borderSize = layer.outsideBorder;
        let left = layer.x - borderSize;
        let top = layer.y - borderSize;
        let right = left + layer.width + borderSize;
        let bottom = top + layer.height + borderSize;

        if(frame.left===undefined || frame.left>left)
          frame.left = left;

        if(frame.right===undefined || frame.right<right)
          frame.right = right;

        if(frame.top===undefined || frame.top>top)
          frame.top = top;

        if(frame.bottom===undefined || frame.bottom<bottom)
          frame.bottom = bottom;
      }
    });

    if(frame.left!==undefined)
    {
      // We hebben geknipt, maar het is wel netjes om asset die binnen de knip
      // vallen volledig te tonen, mogelijk moeten dus de knip weer aanpassen
      tree.walk(layer => {
        const asset = layer.asset;
        const isSvg = asset && asset.type==='svg';
        if(isSvg) {
          let borderSize = layer.outsideBorder;

          let top = layer.y - borderSize;
          let bottom = layer.y + layer.height + borderSize;
          let left = layer.x - borderSize;
          let right = layer.x + layer.width + borderSize;

          // Deze svg valt (gedeeltelijk) binnen de knip
          const hit = (
            (
              (right > frame.left && right<frame.right) ||
              (right > frame.right && left<frame.right)
            )
            &&
            (
              (bottom > frame.top && bottom<frame.bottom) ||
              (bottom > frame.bottom && top<frame.bottom)
            )
          );

          if(hit) {
            let top = layer.y - borderSize;
            let bottom = layer.y + layer.height + borderSize;
            let left = layer.x - borderSize;
            let right = layer.x + layer.width + borderSize;

            if(frame.left>left)
              frame.left = left;

            if(frame.right<right)
              frame.right = right;

            if(frame.top>top)
              frame.top = top;

            if(frame.bottom<bottom)
              frame.bottom = bottom;
          }
        }else if (!asset) {
          // Alle belangrijke assets moeten al afgehandeld zijn, nu alleen de niet assets
          // meestal zijn dit border-achtige elementen
          let borderSize = layer.outsideBorder;
          let top = layer.y - borderSize;
          let bottom = layer.y + layer.height + borderSize;
          let left = layer.x - borderSize;
          let right = layer.x + layer.width + borderSize;

          const hit = (
            (
              (right > frame.left && right<frame.right) ||
              (right > frame.right && left<frame.right)
            )
            &&
            (
              (bottom > frame.top && bottom<frame.bottom) ||
              (bottom > frame.bottom && top<frame.bottom)
            )
          );

          if(hit) {
            // item valt ieg gedeeltelijk binnen de knip
            const maxDiff = 20;

            // Als een kant minder dan maxDiff uitsteekt dan frame aanpassen
            const diffLeft = frame.left - left;
            if(diffLeft < maxDiff && diffLeft>0)
              frame.left = left;

            const diffRight = right - frame.right;
            if(diffRight < maxDiff && diffRight>0)
              frame.right = right;

            const diffTop = frame.top - top;
            if(diffTop < maxDiff && diffTop>0)
              frame.top = top;

            const diffBottom = bottom - frame.bottom;
            if(diffBottom < maxDiff && diffBottom>0)
              frame.bottom = bottom;

          }
        }
      });

      // validate frame, mag niet buiten artBox komen
      const artBox = toJS(this.blueprint.artBox);
      frame.top = Math.max(artBox.y, frame.top);
      frame.bottom = Math.min(artBox.height + artBox.y, frame.bottom);
      frame.left = Math.max(artBox.x, frame.left);
      frame.right = Math.min(artBox.width + artBox.x, frame.right);
      frame.width = frame.right - frame.left;
      frame.height = frame.bottom - frame.top;

      const cutout = {
        x: frame.left,
        y: frame.top,
        width: frame.width,
        height: frame.height,
      }

      return cutout;
    }
  }

  dispose() {
    for(const disposer of this.disposers)
      disposer();
  }
}

export default PageModel;