import css from 'styled-jsx/css'

export default css`
  @import 'https://fonts.googleapis.com/css2?family=Noto+Emoji:wght@500&display=swap';

  @font-face {
    font-family: 'TextSecuritySquare';
    src: url('${process.env.ASSET_URL}/_static/fonts/text-security-square-compat.woff2') format('woff2'), url('${process.env.ASSET_URL}/_static/fonts/text-security-square-compat.ttf') format('truetype')
  }

  @font-face {
    font-family: emoji;

    src: local('Apple Color Emoji'),
        local('Android Emoji'),
        local('Segoe UI'),
        local(EmojiSymbols),
        local(Symbola);

    /* Emoji unicode blocks */
    unicode-range: U+1F300-1F5FF, U+1F600-1F64F, U+1F680-1F6FF, U+2600-26FF;
  }
`